const TABLET_SCREEN_WIDTH = 720;

export default function useWindowResize() {
  const width = ref();
  const height = ref();
  const isMobileScreen = ref();

  function handler() {
    width.value = window?.innerWidth;
    height.value = window?.innerHeight;
    isMobileScreen.value = window?.innerWidth < TABLET_SCREEN_WIDTH;
  }

  onMounted(() => {
    width.value = window?.innerWidth;
    height.value = window?.innerHeight;
    isMobileScreen.value = window?.innerWidth < TABLET_SCREEN_WIDTH;
    window.addEventListener("resize", handler);
  });
  onUnmounted(() => window.removeEventListener("resize", handler));

  return { width, height, isMobileScreen };
}